import React from "react";
import styles from "./header.module.scss";
export const HeaderText = () => {
  return (
    <article>
      {/* <header className={styles.logo}>
        Coin<span>Balance</span>
      </header> */}
      <p className={styles.txt}>Simply input the scratch-card address</p>
    </article>
  );
};
