import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { networksApi } from "@src/api";

export interface NetworksResponse {
  amount: string;
  amountUsd: string;
  currency: string;
  currencyId: string;
  network: string;
  symbol?: string;
  addressLinkExplorer?: string;
}

interface networksState {
  data: NetworksResponse[] | null;
  loading: boolean;
  error?: string | null;
  isExistSearch: boolean;
  inputValue: string;
}

const initialState: networksState = {
  data: null,
  loading: false,
  error: null,
  isExistSearch: false,
  inputValue: "",
};

export const fetchBalance = createAsyncThunk<
  { data: NetworksResponse[]; isSearch: boolean },
  { address: string; isSearch?: boolean }
>("balance/fetchBalance", async ({ address, isSearch = false }, thunkAPI) => {
  try {
    const response = await networksApi.get(
      `/evm-balances?address=${address}&includeAmountUsd=true`
    );

    return { data: response.data?.result, isSearch };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
const networksSlice = createSlice({
  name: "networks",
  initialState,
  reducers: {
    setIsExistSearch(state, action: PayloadAction<boolean>) {
      state.isExistSearch = action.payload;
    },
    setInputValue(state, action: PayloadAction<string>) {
      state.inputValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBalance.fulfilled,
        (
          state,
          action: PayloadAction<{ data: NetworksResponse[]; isSearch: boolean }>
        ) => {
          state.loading = false;
          state.data = action.payload.data;

          if (action.payload.isSearch) {
            state.isExistSearch = true;
          }
        }
      )
      .addCase(fetchBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.data = [];
      });
  },
});

export const { setIsExistSearch, setInputValue } = networksSlice.actions;

export default networksSlice.reducer;
