import React from "react";
import styles from "./ModalQR.module.scss";
import { ReactComponent as AddressRightSvg } from "@assets/svg/AddressRight.svg";
import { ReactComponent as AddressLeftSvg } from "@assets/svg/AddressLeft.svg";
import { ReactComponent as Close } from "@assets/svg/close.svg";
import { useParams } from "react-router-dom";

interface ChildComponentProps {
  closeModal?: () => void;
  qrLink: string;
}
export const ModalQR = ({ closeModal, qrLink }: ChildComponentProps) => {
  const { address } = useParams();

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <header className={styles.logo}>
          Coin<span>Balance</span>
        </header>
        <figure onClick={closeModal} className={styles.close_modal}>
          <Close />
        </figure>
      </section>
      <figure className={styles.qr_img}>
        <img src={qrLink} alt="QR" />
      </figure>
      <article className={styles.address_wrapper}>
        <AddressLeftSvg />
        <p>Address</p>
        <AddressRightSvg />
      </article>
      <p className={styles.address}>{address}</p>
    </div>
  );
};
