import React from "react";
import styles from "./notfound_search.module.scss";
import { ReactComponent as Close } from "@assets/svg/close.svg";
import { setIsExistSearch } from "@root/src/redux/networkSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@root/src/store";
import { useNavigate } from "react-router-dom";

export const NotFoundSearch = ({ address }: { address: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <article className={styles.container}>
      <article className={styles.input_wrapper}>
        <article className={styles.input_container}>
          <p>{address}</p>
          <figure
            onClick={() => {
              dispatch(setIsExistSearch(false));
              navigate("/");
            }}
            className={styles.close}
          >
            <Close />
          </figure>
        </article>
      </article>
    </article>
  );
};
