import React from "react";
import styles from "./row.module.scss";
import { NetworksResponse } from "src/redux/networkSlice";
import { coins } from "@src/utils";
import useWindowSize from "@root/src/assets/svg/hooks/useWindowSize";

interface ChildComponentProps {
  data: NetworksResponse;
  count: number;
}
export const Row = ({ data, count }: ChildComponentProps) => {
  const { width } = useWindowSize();

  return (
    <article className={styles.container}>
      <article className={styles.left_column}>
        <p className={styles.count}>{count}</p>
        <article className={styles.icon_wrapper}>
          <img src={coins[`${data.symbol}`]} alt={coins[`${data.symbol}`]} />
          <p className={styles.symbol}>{data.symbol}</p>
          {width > 600 && <p className={styles.network}>{data.network}</p>}
        </article>
      </article>
      <article className={styles.right_column}>
        <p className={styles.coin}>
          {Number(data.amount).toFixed(5)}
          <span>{data.symbol}</span>
        </p>
        <p className={styles.dollar}>${data.amountUsd}</p>
      </article>
    </article>
  );
};
