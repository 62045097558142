import React, { useState } from "react";
import styles from "./search.module.scss";
import { ReactComponent as QR } from "@assets/svg/qr_white.svg";
import { ReactComponent as SearchIcon } from "@assets/svg/SearchIcon.svg";
import { AppDispatch } from "@root/src/store";
import { useDispatch } from "react-redux";
import { setInputValue } from "@root/src/redux/networkSlice";

interface ChildComponentProps {
  openModal?: () => void;
  handleClick: (val?: string) => void;
  withoutLogo?: boolean;
}
export const Search: React.FC<ChildComponentProps> = ({
  openModal,
  withoutLogo = false,
  handleClick,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [address, setAddress] = useState<null | string>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick(address ?? "");
      dispatch(setInputValue(address ?? ""));
    }
  };

  const handleSearch = () => {
    handleClick(address ?? "");
    dispatch(setInputValue(address ?? ""));
  };

  return (
    <article className={styles.container}>
      {!withoutLogo && (
        <article className={styles.logo}>
          Coin<span>Balance</span>
          <div className={styles.differ}></div>
        </article>
      )}

      <div className={styles.input_wrapper} data-active={withoutLogo}>
        <article className={styles.input_container}>
          <figure className={styles.search_icon} data-active={withoutLogo}>
            <SearchIcon />
          </figure>
          <input
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            type="text"
            placeholder="Search addresses..."
            data-active={withoutLogo}
          />
        </article>
        <article className={styles.qr_wrapper}>
          <button className={styles.btn_qr} onClick={openModal}>
            <QR />
          </button>
          <button className={styles.btn_search} onClick={handleSearch}>
            Search
          </button>
        </article>
      </div>
    </article>
  );
};
