import React from "react";
import styles from "./header_text.module.scss";
import useWindowSize from "@root/src/assets/svg/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { ReactComponent as BurgerMenuIcon } from "@assets/svg/BurgerMenu.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";

interface BurgerMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (open: boolean) => void;
}

export const Header = ({ isMenuOpen, setIsMenuOpen }: BurgerMenuProps) => {
  const location = useLocation();

  const { width } = useWindowSize();

  const renderHeaderLogo = () => {
    switch (true) {
      case location.pathname === "/":
        return width < 600 ? (
          <article className={styles.logo_wrapper}>
            <p className={styles.logo}>
              Coin<span>Balance</span>
            </p>
            <figure onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <CloseIcon /> : <BurgerMenuIcon />}
            </figure>
          </article>
        ) : (
          <article className={styles.wrapper_not}>
            <p className={styles.logo}>
              Coin<span>Balance</span>
            </p>
          </article>
        );

      case location.pathname.includes("/address"):
        return width < 600 ? (
          <article className={styles.logo_wrapper}>
            <p className={styles.logo}>
              Coin<span>Balance</span>
            </p>
            <figure
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={styles.right_icon}
            >
              {isMenuOpen ? <CloseIcon /> : <BurgerMenuIcon />}
            </figure>
          </article>
        ) : (
          <p className={styles.logo}>
            Coin<span>Balance</span>
          </p>
        );

      default:
        return <p className={styles.logo_text}>Default Content</p>;
    }
  };

  return (
    <header className={styles.container}>
      {renderHeaderLogo()}
      {width > 600 && (
        <div className={styles.menu}>
          <button>About Us</button>
          <button>Why us</button>
          <button>FAQs</button>
          <button>CoinBalance</button>
        </div>
      )}
      {width > 600 && (
        <button className={styles.btn_get_started}>Get Started</button>
      )}
    </header>
  );
};
