import React from "react";
import { Link } from "react-router-dom";
import styles from "./not_found.module.scss";

const NotFound: React.FC = () => {
  return (
    <div className={styles.not_found}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <Link to="/" className={styles.home_link}>
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound;
