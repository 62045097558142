import React from "react";
import styles from "./NoAddresses.module.scss";

import { ReactComponent as BigSearch } from "@assets/svg/BigSearchIcon.svg";

export const NoAddresses = () => {
  return (
    <article className={styles.container}>
      <BigSearch />
      <p className={styles.text}> No addresses found</p>
    </article>
  );
};
