import axios, { AxiosRequestConfig } from "axios";
import { PATH } from "./config";

const commonConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const networksApi = axios.create({
  baseURL: PATH.UTILS,
  ...commonConfig,
});

export const apis = [networksApi];
