import React from "react";
import styles from "./containedAssets.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useTotalAmountUsd } from "@src/utils";
import useWindowSize from "@root/src/assets/svg/hooks/useWindowSize";

export const ContainedAssets = () => {
  const { data } = useSelector((state: RootState) => state.networks);
  const { width } = useWindowSize();

  const total = useTotalAmountUsd(data);

  return (
    <article className={styles.container}>
      <article className={styles.info}>
        <p className={styles.contained}>
          {width > 600 ? "Contained assets" : "Assets"}{" "}
          <span>({data?.length})</span>
        </p>
        <article className={styles.sum}>
          <span className={styles.total}>Total balance:</span>
          <span className={styles.price}>{total?.toFixed(2)}</span>
          <span className={styles.currency}>USD</span>
        </article>
      </article>
      <div className={styles.dot}></div>
    </article>
  );
};
