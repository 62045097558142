import React from "react";
import styles from "./BurgerMenu.module.scss";

const BurgerMenu = () => {
  return (
    <article className={styles["burger-menu-container"]}>
      <article className={`${styles.menu}  ${styles.open} `}>
        <nav>
          <ul>
            <li>
              <a href="#">Get Started</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>

            <li>
              <a href="#">Why us</a>
            </li>
            <li>
              <a href="#">FAQs</a>
            </li>
            <li>
              <a href="#">Check card balance</a>
            </li>
          </ul>
        </nav>
      </article>
    </article>
  );
};

export default BurgerMenu;
