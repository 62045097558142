import USDT from "@assets/svg/coins/USDT.svg";
import ETH from "@assets/svg/coins/ETH.svg";
import USDC from "@assets/svg/coins/USDC.svg";
import ISLM from "@assets/svg/coins/ISLM.svg";
import BNB from "@assets/svg/coins/BNB.svg";
import POL from "@assets/svg/coins/MATIC.svg";
import ARB from "@assets/svg/coins/ARB.svg";
import BASE from "@assets/svg/coins/BASE.svg";
import FTM from "@assets/svg/coins/FTM.svg";
import MNT from "@assets/svg/coins/MNT.svg";
import AVAX from "@assets/svg/coins/AVAX.svg";

import { NetworksResponse } from "./redux/networkSlice";

export const coins: { [key in string]: string } = {
  ETH,
  USDT,
  USDC,
  ISLM,
  BNB,
  POL,
  ARB,
  BASE,
  FTM,
  MNT,
  AVAX,
};

export const coinsName: { [key in string]: string } = {
  USDT: "Tether",
  USDC: "USDC coin",
  ETH: "Ethereum",
  ISLM: "Islamic Coin",
  BNB: "BNB",
  POL: "Polygon",
  ARB: "Arbitrum",
  BASE: "Base",
  FTM: "Fantom",
  MNT: "Mantle",
  AVAX: "Avalanche",
};
export const useTotalAmountUsd = (data: NetworksResponse[] | null) => {
  return data?.reduce((sum, transaction) => {
    return sum + parseFloat(transaction.amountUsd);
  }, 0);
};

export const shortenAddress = (address: string) => {
  const prefix = address.slice(0, 18);
  return `${prefix}...`;
};
