import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.scss";
import { Header } from "../ui/header/Header";
import BurgerMenu from "../burger-menu/BurgerMenu";
import useWindowSize from "@root/src/assets/svg/hooks/useWindowSize";

const Layout = () => {
  const { width } = useWindowSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.layout}>
      <header>
        <nav>
          <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </nav>
      </header>

      <main className={styles.main}>
        {width < 600 && isMenuOpen ? <BurgerMenu /> : <Outlet />}
      </main>
    </div>
  );
};

export default Layout;
