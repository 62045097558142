import React, { useEffect, useState } from "react";
import styles from "./home.module.scss";
import { Search } from "@components/ui/search/Search";
import { HeaderText } from "@components/header/HeaderText";
import { fetchBalance, setIsExistSearch } from "@redux/networkSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@root/src/store";
import { NoAddresses } from "@components/no_addresses/NoAddresses";
import { NotFoundSearch } from "@components/ui/not_found_address/NotFoundAddress";

export const Home = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { inputValue, isExistSearch, loading } = useSelector(
    (state: RootState) => state.networks
  );

  const [isSearch, setSearch] = useState(false);

  const isEmpty = isSearch && isExistSearch && loading === false;

  useEffect(() => {
    setSearch(isExistSearch);
  }, [isExistSearch]);

  const handleSent = async (address?: string) => {
    try {
      if (address) {
        dispatch(setIsExistSearch(true));
        await dispatch(
          fetchBalance({ address: address, isSearch: true })
        ).unwrap();

        window.location.href = `/address/${address}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <article className={styles.container}>
      <article className={styles.wrapper} data-exist={isEmpty}>
        {!isExistSearch && <HeaderText />}
        <Search withoutLogo handleClick={handleSent} />
        {isEmpty && <NotFoundSearch address={inputValue ?? ""} />}

        {isEmpty && <NoAddresses />}
      </article>
    </article>
  );
};
