import React from "react";
import styles from "./assets.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { NetworksResponse } from "src/redux/networkSlice";
import { ReactComponent as LinkIcon } from "@assets/svg/link.svg";
import { Row } from "./row/Row";
import { Link } from "react-router-dom";

export const Assets = () => {
  const { data } = useSelector((state: RootState) => state.networks);
  const groupedByNetworkObj = data?.reduce<Record<string, NetworksResponse[]>>(
    (acc: any, curr: NetworksResponse) => {
      if (!acc[curr.network]) {
        acc[curr.network] = [];
      }
      acc[curr.network].push(curr);
      return acc;
    },
    {}
  );

  const groupedByNetworkArray = Object.entries(groupedByNetworkObj ?? {}).map(
    ([network, currencies]) => ({
      network,
      currencies,
    })
  );

  return (
    <article className={styles.container}>
      {groupedByNetworkArray.map((item, ind) => (
        <div key={ind} className={styles.row_container}>
          <article className={styles.name_wrapper}>
            <p className={styles.networkName}>{item.network} network</p>
            <Link
              target="_blank"
              className={styles.view}
              to={item.currencies[0].addressLinkExplorer ?? ""}
            >
              <span className={styles.link_text}>View in explorer</span>
              <LinkIcon />
            </Link>
          </article>
          {item.currencies.map((curr, ind) => (
            <article key={ind} className={styles.wrapper}>
              <Row data={curr} count={ind + 1} />
            </article>
          ))}
        </div>
      ))}
    </article>
  );
};
