import React, { useEffect, useState } from "react";
import styles from "./networks.module.scss";
import { Address } from "@components/ui/address/Address";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance, setIsExistSearch } from "@redux/networkSlice";
import { AppDispatch, RootState } from "@src/store";
import { ContainedAssets } from "@components/contained_assets/ContainedAssets";
import { Assets } from "@components/assets/Assets";
import { ModalQR } from "@components/modal_qr/ModalQRComponent";
import { NoAddresses } from "@components/no_addresses/NoAddresses";
import { NotFoundSearch } from "@components/ui/not_found_address/NotFoundAddress";
// import { Search } from "@components/ui/search/Search";

export const Networks = () => {
  const { address } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data, isExistSearch, loading, error } = useSelector(
    (state: RootState) => state.networks
  );

  const isShow = data && data.length > 0 && !error && !loading;

  Modal.setAppElement("#root");

  const closeModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const isSent = address && !isExistSearch && !data?.length;
    if (isSent) {
      dispatch(fetchBalance({ address: address, isSearch: false }));
    }
    dispatch(setIsExistSearch(false));
  }, [dispatch, address]);

  // const handleClick = (address: string) => {
  //   dispatch(fetchBalance({ address: address ?? "", isSearch: false }));

  //   dispatch(setIsExistSearch(true));
  //   address?.length && navigate(`/address/${address}`);
  // };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <ModalQR
          closeModal={closeModal}
          qrLink={
            "https://s3-alpha-sig.figma.com/img/fff5/647f/61432101b376e3bdee898de724041a6c?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZIzJd~EYNk799JMI42B5F5c4EkC7HSvb-ocvIL2Sd6p0LlKqNW~q8nrJ8-SuwzWOqXpDvgnTV~-ILmsQDSHgAO7yKhU-PRAYSdk~wx4YhvYdX2ILFZlP4gy2zRBcJ4~85ESVynXUYZDQKx9XM5iD4ZxPB2phi0xkZSqbIpiBtvrWuNToPksXQmTRbyJjzQ-yJb4FkcwYbt5tUaCx5gTQeavIfKuA~Q0uaqnVTY0Rp2xrYSxg3pMH7BforakZIYhnLY30TVv7q~XB~6Q~u8fSfv1lNtQpwrukEhwQH-qyrluZ2yF1EQjazuS6EWwTsi-UsGo-UG20yBAvsUVeCtwqwQ__"
          }
        />
      </Modal>

      <article className={styles.container}>
        <article className={styles.wrapper}>
          {/* <article> */}
          {/* <article className={styles.search_wrapper}>
              <Search
                handleClick={(address) => handleClick(address ?? "")}
                openModal={() => setModalIsOpen(true)}
              />
            </article> */}

          {!isShow && <NotFoundSearch address={address ?? ""} />}
          {/* </article> */}
          <article className={styles.wrapper_addresses}>
            {isShow ? (
              <>
                <Address address={address} />

                <article className={styles.wrapper_info}>
                  <ContainedAssets />
                  <Assets />
                </article>
              </>
            ) : (
              <NoAddresses />
            )}
          </article>
        </article>
      </article>
    </>
  );
};
